import React from "react"

import UserGuideCard from './user-guide-card';

import grid from "../shared/bootstrap-grid.module.css"

const UserGuideList = ({ guides }) => guides.map((guide, index) => (
  <div className={`${grid.colLg6} ${index > 0 ? grid.mt4 : ''} ${index === 1 ? grid.mtLg0 : ''}`}>
    <UserGuideCard 
      title={guide.title}
      description={guide.description}
      time={guide.time}
      url={guide.url}
    />
  </div>
));

export default UserGuideList;