import React from "react"

import Layout from "../../../components/layout"
import Band from "../../../components/band"
import UserGuideList from "../../../components/user-guide-list"
import PhoneValidationTopNav from "../../../components/top-nav/phone-validation-top-nav"
import SEO from "../../../components/seo"
import grid from "../../../shared/bootstrap-grid.module.css"

const guides = [
  // {
  //   title: 'Architectural Overview',
  //   description: 'An architectural overview of the Flow Data Table product.',
  //   time: '15',
  //   url: '/products/flow-data-table/architectural-overview'
  // },
  {
    title: 'Getting Started',
    description: 'Setup your org and build a basic flow with Flow Data Table & Flow Builder.',
    time: '10',
    url: '/products/flow-data-table/classic-user-guides/getting-started'
  },
  {
    title: 'Configuration',
    description: 'View all the configuration options Flow Data Table offers.',
    time: '3',
    url: '/products/flow-data-table/classic-user-guides/configuration'
  },
  {
    title: 'Outputting Selected Records',
    description: 'Output the list of selected records from the data table to subsequent steps of the flow.',
    time: '8',
    url: '/products/flow-data-table/classic-user-guides/output-selected-records'
  },
  {
    title: 'Pre Selecting Records',
    description: 'Display a list of records in the data table with some records already selected.',
    time: '8',
    url: '/products/flow-data-table/classic-user-guides/pre-selecting-records'
  },
  {
    title: 'Edit Records In Memory',
    description: 'Output edited records from the data table to subsequent steps of the flow.',
    time: '8',
    url: '/products/flow-data-table/classic-user-guides/edit-in-memory'
  },
  {
    title: 'Edit Records Real Time',
    description: 'Edit records directly in your orgs database in real time from a flow.',
    time: '8',
    url: '/products/flow-data-table/classic-user-guides/edit-real-time'
  },
  {
    title: 'Record Types',
    description: 'Edit Picklist Values Configured for Record Types.',
    time: '12',
    url: '/products/flow-data-table/classic-user-guides/record-types'
  },
];

const FlowDataTableClassicUserGuidePage = () => (
  <Layout>
    <SEO title="Flow Data Table" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `flow data table`]} />

    <PhoneValidationTopNav />

    <Band>
      <div className={grid.row}>
        <UserGuideList guides={guides} />
      </div>
    </Band>
  </Layout>
);

export default FlowDataTableClassicUserGuidePage;