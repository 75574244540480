import React from "react"
import { Link } from "gatsby"

import cardStyles from "../shared/card.module.css"

const UserGuideCard = ({title, description, time, url}) => (
  <Link to={url} className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
    <h3>{title}</h3>
    <p style={{ flexGrow: '1' }}>
      {description}
    </p>
    <small>{time} mins</small>
  </Link>
);

export default UserGuideCard;